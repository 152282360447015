@import '../../styles/helpers/index.scss';

.counter {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  &__card {
    @include align-center;
    position: relative;
    @include shine;
    @include scale;

    @media screen and (max-width: $tablet-sm) {
      justify-content: center;
      &:not(:last-of-type) {
        margin: 0 0 30px;
      }
    }

    &--text {
      margin: 0 0 0 40px;
      p {
        margin: 0 0 5px;
        font-weight: 800;
        font-size: 60px;
        line-height: 120%;
        text-transform: uppercase;
        color: #d1ae66;
      }
      span {
        font-size: 18px;
        line-height: 150%;
        color: #070707;
        text-transform: uppercase;
      }
    }
  }
}
