@import '../../styles/helpers/index.scss';

.services {
  margin: 140px 0 0;
  padding: 130px 0 140px;
  background-color: map-get($colors, 'green');
  padding-bottom: 100px;
  border-radius: 40px;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
    border-radius: 0;
  }

  .section-title {
    margin: 0 0 50px;
  }

  &__grid {
    @include grid($columns: repeat(3, 1fr));
    &--item {
      @include align-center;
      @include scale;
      flex-direction: column;
      justify-content: center;
      padding: 70px 45px;
      background-color: #fff;
      box-shadow: 4px 4px 30px rgba(7, 7, 7, 0.1);
      border-radius: 40px;

      &__icon {
        height: 100px;
        margin: 0 0 40px;
        @include align-center;
      }

      p {
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
      }
    }
  }
}
