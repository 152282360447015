@import '../../styles/helpers/index.scss';

.pricing {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    margin: 50px 0 0;

    @include grid($column-gap: 30px, $columns: repeat(3, 1fr));

    &--card {
      padding: 70px 30px 80px;
      background: #fff;
      box-shadow: 4px 4px 30px rgba(7, 7, 7, 0.1);
      border-radius: 40px;

      color: map-get($colors, 'text');

      span:nth-of-type(2) {
        display: block;
        font-size: 18px;
        line-height: 150%;
        text-transform: uppercase;
      }

      &__movers {
        display: block;
        margin: 0 0 30px;
        font-weight: 800;
        font-size: 30px;
        line-height: 120%;
        text-transform: uppercase;
        color: map-get($colors, 'green');
      }

      &__rate {
        display: block;
        margin: 15px 0 20px;
        font-weight: 800;
        font-size: 60px;
        line-height: 120%;
        text-transform: uppercase;
        color: #d1ae66;
        span {
          font-size: 40px;
        }
      }

      p {
        font-size: 17px;
        line-height: 150%;
      }

      ul {
        margin: 35px 0 40px;
        padding: 0 0 0 15px;
        font-size: 18px;
        line-height: 200%;
        li {
          &::before {
            content: '\2022';
            color: #c49b53;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
    }
  }
}
