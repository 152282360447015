@import '../../styles/helpers/index.scss';

.why-we {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    margin: 0 0 50px;
  }

  &__reasons {
    @include grid($columns: repeat(4, 1fr));

    @media screen and (max-width: $tablet-sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    &--item {
      @include align-center;
      flex-direction: column;
      justify-content: center;

      min-height: 280px;

      box-shadow: 4px 4px 30px rgba(7, 7, 7, 0.1);
      border-radius: 40px;

      color: map-get($colors, 'text');

      @include scale;

      @media screen and (max-width: $tablet-sm) {
        min-height: unset;
        padding: 30px 0;
      }

      &__icon {
        margin: 0 0 40px;
        height: 80px;

        @media screen and (max-width: $tablet-sm) {
          height: 60px;
          margin: 0 0 20px;
        }

        img {
          height: inherit;
          object-fit: cover;
        }
      }

      p {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        @media screen and (max-width: $tablet-sm) {
          font-size: 14px;
          padding: 0 15px;
        }
      }
    }
  }
}
