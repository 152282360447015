@import '../../styles/helpers/index.scss';

.areas {
  margin: 140px 0 0;
  padding: 100px 0 90px;

  background-image: url('../../static/images/bg/areas.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  border-radius: 40px;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
    padding: 70px 0;
    border-radius: 0;
  }

  ul {
    margin: 40px 0 0;
    color: #fff;
    font-size: 18px;
    line-height: 200%;
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      columns: 2;
    }

    li {
      position: relative;
      padding: 0 0 0 21px;

      &::before {
        content: '';

        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        height: 8px;
        width: 6px;

        background-image: url('../../static/images/icons/list-before.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  &__map {
    margin: 50px 0 0;
  }
}
