@import '../../styles/helpers/index.scss';

.hero {
  padding: 70px 0;

  background-image: url('../../static/images/bg/hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    background-image: none;
    padding: 0;

    color: map-get($colors, 'text');
  }

  &__mobile-image {
    display: none;
    background-image: url('../../static/images/bg/hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    min-height: 250px;
    margin: 0 0 30px;
    padding: 0 15px 30px;

    color: #fff;

    flex-direction: column;
    justify-content: flex-end;

    h1 {
      margin: 0 0 15px;
      font-weight: 800;
      font-size: 32px;

      line-height: 116%;

      text-shadow: 2px 2px 10px rgba(#000, 0.7);
    }

    p {
      font-size: 16px;
      line-height: 116%;
      text-shadow: 2px 2px 10px rgba(#000, 0.7);
    }

    @media screen and (max-width: $tablet-sm) {
      display: flex;
    }
  }

  .container {
    @include grid;
  }

  &__content {
    h1 {
      margin: 0 0 35px;
      font-weight: 800;
      font-size: 60px;
      line-height: 116%;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    p {
      font-size: 24px;
      line-height: 116%;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }
  }

  &__banners {
    margin: 70px 0 0;
    @include grid;

    @media screen and (max-width: $tablet-sm) {
      margin: 10px 0 0;
      grid-row-gap: 20px;
    }

    &--item {
      padding: 10px 30px;
      border-radius: 20px;
      background: rgba(#fff, 0.8);
      color: map-get($colors, 'text');

      @include align-center;

      @include scale;

      @media screen and (max-width: $tablet-sm) {
        background: rgba(#000, 0.2);
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        justify-content: space-between;
      }

      &:nth-of-type(3) {
        grid-area: 2 / 1 / 3 / 3;
        padding: 25px 30px;

        @media screen and (max-width: $tablet-sm) {
          grid-area: unset;
          justify-content: space-between;
          padding: 10px 30px;
        }

        .hero__banners--item__description {
          flex-direction: row;

          @media screen and (max-width: $tablet-sm) {
            flex-direction: column;
          }

          div {
            order: 3;
            @media screen and (max-width: $tablet-sm) {
              order: unset;
            }
          }

          span {
            display: block;
            margin: 0;

            @media screen and (max-width: $tablet-sm) {
              margin: 0 0 10px;
            }
          }

          strong {
            display: block;
            margin: 0 10px 0 5px;

            @media screen and (max-width: $tablet-sm) {
              margin: 10px 0 0;
            }
          }
        }
      }

      &__icon {
        @media screen and (max-width: $tablet-sm) {
          max-width: 50%;
          width: 100%;
          img {
            margin: 0 auto;
            max-height: 70px;
          }
        }
      }

      &__description {
        @include align-center;
        flex-direction: column;
        justify-content: center;

        font-size: 18px;
        line-height: 110%;

        margin: 0 0 0 25px;

        @media screen and (max-width: $tablet-sm) {
          margin: 0;
        }

        span {
          margin: 0 0 10px;
          white-space: nowrap;
        }
        strong {
          margin: 5px 0 0;
          font-weight: bold;
        }
      }

      &__stars {
        @include align-center;
        img {
          &:not(:last-of-type) {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }

  &__form {
    background-color: map-get($colors, 'green');
    border-radius: 40px;
    padding: 55px 30px 25px;
    min-height: 582px;

    @media screen and (max-width: $tablet-sm) {
      overflow: hidden;
    }

    h2 {
      margin: 0 0 30px;
      font-weight: 800;
      font-size: 40px;
      line-height: 116%;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
