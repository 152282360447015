@import '../../styles/helpers/index.scss';

.moving-estimate {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__inner {
    padding: 80px 30px 90px;
    background-image: url('../../static/images/bg/cta.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-radius: 40px;

    @include align-center;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    .section-title {
      @media screen and (max-width: $tablet-sm) {
        text-align: center;
      }
    }

    .gradient-btn {
      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
      }
    }
  }
}
