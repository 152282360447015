@import '../../styles/helpers/index.scss';

.book-now {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__inner {
    padding: 80px 30px 90px;
    background-image: url('../../static/images/bg/cta2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-radius: 40px;
  }

  &__links {
    margin: 50px 0 0;
    @include align-center;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      margin: 0 0 0 30px;
      font-weight: 800;
      font-size: 24px;
      line-height: 200%;
      color: #fff;

      @media screen and (max-width: $tablet-sm) {
        margin: 15px 0 0;
      }

      a {
        color: #fff;
      }
    }
  }
}
