@mixin space-between {
  display: flex;
  justify-content: space-between;
}

@mixin space-around {
  display: flex;
  justify-content: space-around;
}

@mixin align-center {
  display: flex;
  align-items: center;
}

@mixin scale {
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}

@mixin grid($row-gap: 40px, $column-gap: 40px, $rows: 1fr, $columns: repeat(2, 1fr)) {
  display: grid;
  grid-template-rows: $rows;
  grid-template-columns: $columns;
  grid-row-gap: $row-gap;
  grid-column-gap: $column-gap;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin shine {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 0% 100%;
  }

  &:hover {
    &::before {
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.7) 100%
      );
      background-repeat: no-repeat;
      background-size: 200% 100%;
      transition: background-size 1s, background-color 1s;
    }
  }
}
