@import '../../styles/helpers/index.scss';

.contact-us {
  margin: 120px 0 140px;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0;
  }

  &__inner {
    padding: 115px 50px 40px 30px;
    background-image: url('../../static/images/bg/cta3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    color: #fff;

    border-radius: 40px;

    @media screen and (max-width: $tablet-sm) {
      border-radius: 0;
      padding: 50px 30px;
    }

    p {
      margin: 25px 0 0;
      font-size: 24px;
      line-height: 150%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;

        br {
          display: none;
        }
      }
    }
  }

  &__links {
    margin: 45px 0 0;
    @include align-center;

    span {
      font-weight: 600;
      font-size: 30px;
      line-height: 150%;
      margin: 0 0 0 20px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 20px;
      }
    }
  }

  &__payments {
    margin: 35px 0 0;
    @include align-center;
    justify-content: flex-end;

    @media screen and (max-width: $tablet-sm) {
      justify-content: space-around;
    }

    img {
      @media screen and (max-width: $tablet-sm) {
        height: 40px;
      }
      &:not(:last-of-type) {
        margin: 0 20px 0 0;

        @media screen and (max-width: $tablet-sm) {
          margin: 0;
        }
      }
    }
  }
}
