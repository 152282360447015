@import './helpers/reset.scss';
@import './helpers/variables.scss';
@import './helpers/mixins.scss';

body {
  height: inherit;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Open Sans', sans-serif;
  color: map-get($colors, 'text');

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  main {
    flex: 1 0 auto;
    padding-top: 100px;
    @media screen and (max-width: $tablet-sm) {
      padding-top: 119px;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }
}

.gradient-btn {
  position: relative;
  display: inline-block;
  padding: 14px 20px;
  cursor: pointer;

  background: linear-gradient(92.53deg, #c49b53 0%, #efdb93 100%);

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: map-get($colors, 'text');

  @include shine;

  @media screen and (max-width: $tablet-sm) {
    padding: 12px 15px;
    font-size: 16px;
    line-height: 100%;
  }

  &.phone {
    padding-left: 50px;

    &::after {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);

      height: 20px;
      width: 20px;

      background-image: url('../static/images/icons/phone.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media screen and (max-width: $tablet-sm) {
        height: 15px;
        width: 15px;
      }
    }
  }

  &.truck {
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    border: none;
    padding-left: 80px;

    @media screen and (max-width: $tablet-sm) {
      padding: 15px 15px 15px 80px;
      font-size: 16px;
      line-height: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);

      width: 35px;
      height: 40px;

      background-image: url('../static/images/icons/truck.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}

.section-title {
  font-weight: 800;
  font-size: 56px;
  line-height: 120%;
  text-transform: uppercase;
  color: map-get($colors, 'text');

  @media screen and (max-width: $tablet-sm) {
    font-size: 32px;
  }

  span {
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      background: linear-gradient(92.53deg, #c49b53 0%, #efdb93 100%);
      width: calc(100% + 10px);
      height: 30px;

      position: absolute;
      left: -5px;
      bottom: 0;

      z-index: -1;
    }
  }

  &.white {
    color: #fff;
  }
}

.mobile-br {
  display: none;
  @media screen and (max-width: $tablet-sm) {
    display: inline-block;
  }
}

#booking-page {
  .book-now {
    text-align: center;
    margin: 70px 0;
  }

  .section-title {
    margin: 0 0 90px;
  }

  .form-wrapper {
    background-color: map-get($colors, 'green');
    padding: 60px 30px;
    border-radius: 40px;
  }
}
