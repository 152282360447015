@import '../../styles/helpers/index.scss';

.order-form {
  // margin: 0 0 40px;

  @media screen and (max-width: $tablet-sm) {
  }

  select,
  input {
    max-width: 100%;
    width: 100%;
    padding: 10px;
    height: 50px;

    appearance: none;
    line-height: 155%;
    font-size: 14px;

    outline: none;

    border: none;

    appearance: none;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      font-family: 'Open Sans', sans-serif;
      color: rgba(#162433, 0.5);
    }

    &:disabled {
      background-color: #fff;
      opacity: 0.7;
    }
  }

  &__caption {
    margin: 15px 0 0;
    font-size: 12px;
    padding: 10px;
  }

  &__step1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    p {
      margin: 15px 0;
      width: 100%;
      text-align: center;
    }

    .input-wrapper {
      max-width: 48%;
      width: 100%;
      margin: 0 0 15px;
    }

    .order-form__buttons {
      justify-content: flex-end;
    }

    .addition-line {
      max-width: 100%;
      width: 100%;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & > div {
        margin: 0 0 5px;
        max-width: 100%;
        width: 100%;

        position: relative;
        display: table;
        border-collapse: separate;

        span {
          border-radius: 0;
          &:first-of-type {
            border-right: 0;
            display: table-cell;
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            color: #555;
            text-align: center;
            background-color: #eee;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 30%;
            white-space: nowrap;
            vertical-align: middle;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-of-type {
            border-left: 0;
            display: table-cell;
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            color: #555;
            text-align: center;
            background-color: #eee;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 10%;
            white-space: nowrap;
            vertical-align: middle;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        input {
          display: table-cell;
          position: relative;
          z-index: 2;
          float: left;
          width: 100%;
          margin-bottom: 0;
          height: 50px;
          border-color: rgba(52, 52, 52, 0.2);
          border-radius: 0;
          color: #232323;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 16px;
        }
      }
    }
  }

  &__step2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    input {
      &[type='text'] {
        margin: 0 0 15px;
        max-width: 68%;
      }

      &[type='number'] {
        max-width: 30%;
      }
    }
  }

  &__step3 {
    margin: 15px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
      margin: 0 0 10px;
      width: 100%;
    }

    .full-width {
      max-width: 100%;
    }

    .half-width {
      max-width: 48%;
    }

    textarea {
      width: 100%;
      height: 100px;
      padding: 10px;
      outline: none;
      border: none;

      &::placeholder {
        font-family: 'Open Sans', sans-serif;
        color: rgba(#162433, 0.5);
      }
    }
  }

  .gradient-btn {
    &:disabled {
      opacity: 0.3;
      user-select: none;
      cursor: not-allowed;
    }
  }

  .prevButton,
  .nextButton {
    min-height: 46px;
    cursor: pointer;
    padding: 5px 10px;
    border: 0;

    border: 1px solid #fff;
    background-color: map-get($colors, 'green');
    color: #fff;

    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;

    transition: all 0.3s ease;

    &:not(:disabled):hover {
      border-color: map-get($colors, 'green');
      color: map-get($colors, 'green');
      background-color: #fff;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &__buttons {
    margin: 15px 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      &:disabled {
        opacity: 0.3;
        user-select: none;
      }
    }
  }
}
