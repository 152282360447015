@import '../../styles/helpers/index.scss';

.about {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    margin: 0 0 60px;

    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 30px;
    }
  }

  &__content {
    @include grid;

    &--text {
      p {
        color: map-get($colors, 'text');
        font-size: 18px;
        line-height: 150%;

        @media screen and (max-width: $tablet-sm) {
          font-size: 16px;
        }

        &:not(:last-of-type) {
          margin: 0 0 15px;
        }
      }
    }

    &--awards {
      background-image: url('../../static/images/bg/about_awards.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      min-height: 550px;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }
  }
}
