@import '../../styles/helpers/index.scss';

.reviews {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    margin: 90px 0 0;
    &--card {
      position: relative;
      margin: 40px 20px;
      padding: 85px 30px 60px;
      background: #ffffff;
      box-shadow: 4px 4px 30px rgba(7, 7, 7, 0.1);
      border-radius: 40px;
      color: map-get($colors, 'text');

      &__header {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);

        background-color: map-get($colors, 'green');
        height: 80px;
        width: 80px;

        border-radius: 50%;

        @include align-center;
        justify-content: center;
      }

      &__stars {
        @include align-center;
        justify-content: center;

        img {
          &:not(:last-of-type) {
            margin: 0 10px 0 0;
          }
        }
      }

      &__name {
        text-align: center;
        margin: 40px 0 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
      }

      &__date {
        margin: 15px 0 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: rgba(7, 7, 7, 0.5);
      }

      &__text {
        margin: 20px 0 0;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        text-align: center;

        height: 270px;
        overflow: scroll;
      }
    }
  }
}

.slick-slider {
  .slick-next,
  .slick-prev {
    width: 60px;
    height: 60px;
    background: #fff;
    border: 3px solid map-get($colors, 'green');
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -60px;
    border-radius: 50%;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;

      background-repeat: no-repeat;
      background-size: contain;

      width: 20px;
      height: 20px;

      top: 50%;
      transform: translate(-50%, -50%);
    }
    @media screen and (max-width: $tablet-sm) {
      top: -40px;
      width: 45px;
      height: 45px;
    }
  }
  .slick-next {
    right: 20px;

    &::before {
      left: 60%;
      background-image: url('../../static/images/icons/next-btn.svg');
    }

    @media screen and (max-width: $tablet-sm) {
      right: 0;
    }
  }
  .slick-prev {
    right: 120px;
    left: unset;

    &::before {
      left: 55%;
      background-image: url('../../static/images/icons/prev-btn.svg');
    }

    @media screen and (max-width: $tablet-sm) {
      right: auto;
      left: 0;
    }
  }
}
